import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Row,
    Col,
    ListGroup,
    Image,
    Button,
    Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { PayPalButtons, SCRIPT_LOADING_STATE, usePayPalScriptReducer,  } from "@paypal/react-paypal-js";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
    useGetOrderDetailsQuery,
    usePayOrderMutation,
    useGetPayPalClientIdQuery,
    useDeliverOrderMutation,
} from "../slices/ordersApiSlices";
import { addDecimals } from "../utils/cartUtils";
import { AuthState, RootState, order } from "../types/interfaces";


const OrderScreen = () => {
    const { id: orderId } = useParams();
    const {
        data: order,
        refetch,
        isLoading,
        error,
    } = useGetOrderDetailsQuery(orderId as string); // idk how to work around

    const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation();
    const [deliverOrder, {isLoading:loadingDeliver }] = useDeliverOrderMutation();
    const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();
    const {
        data: paypal,
        isLoading: loadingPayPal,
        error: errorPayPal,
    } = useGetPayPalClientIdQuery();
    const { userInfo } = useSelector<RootState, AuthState>(
        (state) => state.auth
    );

    useEffect(() => {
        if (!errorPayPal && !loadingPayPal && paypal && paypal.clientId) {
            const loadPayPalScript = async () => {
                paypalDispatch({
                    type: "resetOptions",
                    // value: {
                    //     "client-id": paypal.clientId,
                    //     currency: "USD",
                    // },
                    value: {
                        clientId: paypal.clientId,
                        currency: "USD",
                    },
                });
                paypalDispatch({ type: "setLoadingStatus", value: SCRIPT_LOADING_STATE.PENDING });
            };
            if (order && !order.isPaid) {
                if (!window.paypal) {
                    loadPayPalScript();
                }
            }
        }
    }, [order, paypal, paypalDispatch, loadingPayPal, errorPayPal]);

    function onApprove(data:any, actions:any) {
        return actions.order.capture().then( async(details:any) => {
            const name = details.payer.name.given_name;
            console.log(`Transaction completed by ${name}`);
            try {
                await payOrder({ orderId, details });
                refetch();
                toast.success("Payment Successful");
            } catch (error: any) {
                toast.error(error?.data?.message || error?.message);
            }
        });
    }
    // async function onApproveTest() {
    //     let clientId:string='0';
    //     await payOrder({ clientId, details: { payer: {} } });
    //     refetch();
    //     toast.success("Payment Successful");
    // }
    function onError(err: any) {
        toast.error(err.message);
        console.log("error right here");
    }
    function createOrder(data:any, actions:any) {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: 'USD',
                        value: (order as order).totalPrice,
                    },
                },
            ],
        });
    }

    if (isLoading || !orderId) {
        return <Loader />;
    }

    const deliverOrderHandler =async () => {
        try {
            await deliverOrder(orderId);
            refetch();
            toast.success('Order Delivered');
        } catch (error:any) {
            toast.error(error?.data?.message || error.message);
        }
    };


    if (error) {
        if ("status" in error) {
            const errMsg =
                "error" in error ? error.error : JSON.stringify(error.data);
            return (
                <div>
                    <div>An error has occured:</div>
                    <Message variant="danger">{errMsg}</Message>
                </div>
            );
        } else {
            return <Message variant="danger">{error.message}</Message>;
        }
    }
    if (order === undefined) {
        return <div>No order found.</div>;
    }

    return (
        <>
            <h1>Order {order._id}</h1>
            <Row>
                <Col md={8}>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <h2>Shipping</h2>
                            <p>
                                <strong>Name: </strong> {order.user.name}
                            </p>
                            <p>
                                <strong>Email: </strong> {order.user.email}
                            </p>
                            <p>
                                <strong>Address: </strong>
                                {order.shippingAddress.address},{" "}
                                {order.shippingAddress.city}{" "}
                                {order.shippingAddress.postalCode},{" "}
                                {order.shippingAddress.country}
                            </p>
                            {order.isDelivered ? (
                                <Message variant="success">
                                    Delivered on {order.deliveredAt}
                                </Message>
                            ) : (
                                <Message variant="danger">
                                    Not Delivered
                                </Message>
                            )}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h2>Payment Method</h2>
                            <p>
                                <strong>Method: </strong>
                                {order.paymentMethod}
                            </p>
                            {order.isPaid ? (
                                <Message variant="success">
                                    Paid on {order.paidAt}
                                </Message>
                            ) : (
                                <Message variant="danger">Not Paid</Message>
                            )}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h2>Order Items</h2>
                            {order.orderItems.map((item, index) => (
                                <ListGroup.Item key={index}>
                                    <Row>
                                        <Col md={1}>
                                            <Image
                                                src={item.image}
                                                alt={item.name}
                                                fluid
                                                rounded
                                            />
                                        </Col>
                                        <Col>
                                            <Link
                                                to={`/product/${item.product}`}
                                            >
                                                {item.name}
                                            </Link>
                                        </Col>
                                        <Col md={4}>
                                            {item.qty} x $
                                            {addDecimals(item.price)} = $
                                            {addDecimals(item.qty * item.price)}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <h2>Order Summary</h2>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>Items</Col>
                                    <Col>${addDecimals(order.itemsPrice)}</Col>
                                </Row>
                                <Row>
                                    <Col>Shipping</Col>
                                    <Col>
                                        ${addDecimals(order.shippingPrice)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Tax</Col>
                                    <Col>${addDecimals(order.taxPrice)}</Col>
                                </Row>
                                <Row>
                                    <Col>Total</Col>
                                    <Col>${addDecimals(order.totalPrice)}</Col>
                                </Row>
                            </ListGroup.Item>
                            {!order.isPaid && (
                                <ListGroup.Item>
                                    {loadingPay && <Loader />}

                                    {isPending ? (
                                        <Loader />
                                    ) : (
                                        <div>
                                            {/* <Button
                                                onClick={onApproveTest}
                                                style={{ marginBottom: "10px" }}
                                            >
                                                Test Pay Order
                                            </Button>
                                            */}
                                            <div>
                                                <PayPalButtons
                                                    createOrder={(data,actions)=>createOrder(data,actions)}
                                                    onApprove={(data,actions)=>onApprove(data,actions)}
                                                    onError={onError}
                                                ></PayPalButtons>
                                            </div>
                                        </div>
                                    )}
                                </ListGroup.Item>
                            )}

                            {loadingDeliver && <Loader />}

                            {userInfo && userInfo.isAdmin && order.isPaid && 
                            !order.isDelivered && (
                                <ListGroup.Item>
                                    <Button type='button' className="btn btn-block" onClick={deliverOrderHandler}>
                                        Mark As Delivered
                                    </Button>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default OrderScreen;
